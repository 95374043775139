<template>
  <div>
    <div class="globle_border">
      <div class="search-box">
        <div class="input-box">
          <el-input v-model="categoryName" placeholder="搜索分类名称" @change="onScreenData"></el-input>
        </div>
        <el-button type="primary" @click="handleAdd">新增一级分类</el-button>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" row-key="categoryId" max-height="540" v-loading="dataLoding">
          <el-table-column show-overflow-tooltip min-width="120" prop="categoryName" label="分类名称"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="" label="分类类型">
            <template slot-scope="scope">
              <span v-if="scope.row.parentCategoryId == 0">{{ scope.row.secondType == 1 ? "商品分类" : scope.row.secondType
            == 2 ? "教育分类" : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="" label="图片">
            <template slot-scope="scope" v-if="scope.row.parentCategoryId == 0">
              <div style="color: #069bbc; cursor: pointer" @click="handleCommand(scope.row)">查看图片</div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="sort" label="排序"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="300" fixed="right">
            <template slot-scope="scope">
              <el-button v-if="scope.row.parentCategoryId == 0" class="btn" type="primary" plain
                @click="handleAddTwo(scope.row)">新增二级分类</el-button>
              <el-button v-if="scope.row.parentCategoryId > 0" class="btn" type="primary"
                @click="handleUpdateProduct(scope.row)">添加商品</el-button>
              <el-button class="btn" type="" plain @click="handleEdit(scope.row)">修改</el-button>
              <el-button class="btn" type="danger" plain @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <!-- 申请分类弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="955px" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo_ruleForm">
        <el-row>
          <el-col :span="11">
            <el-form-item label="一级类目" prop="parentCategoryName">
              <el-input v-model="ruleForm.parentCategoryName" placeholder="请输入一级类目" :disabled="twoEdit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="一级排序" prop="parentCategorySort">
              <el-input v-model="ruleForm.parentCategorySort" placeholder="请输入一级排序" :disabled="twoEdit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="twoEdit">
            <el-form-item label="二级类目" prop="categoryName">
              <el-input v-model="ruleForm.categoryName" placeholder="请输入二级类目"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="twoEdit">
            <el-form-item label="二级排序" prop="sort">
              <el-input v-model="ruleForm.sort" placeholder="请输入二级排序"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="!twoEdit">
            <el-form-item label="分类类型" prop="secondType">
              <el-select v-model="ruleForm.secondType" placeholder="请选择分类类型">
                <el-option label="商品分类" value="1"></el-option>
                <el-option label="教育分类" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" v-if="!twoEdit">
            <el-form-item label="图片" prop="picUrl">
              <el-upload :action="$axios.defaults.baseURL + 'attachment/uploadFile'" :show-file-list="false"
                name="multipartFile" accept=".png,.jpeg,.jpg" :on-success="handleSuccess" :before-upload="beforeUpload"
                list-type="picture">
                <img v-if="ruleForm.picUrl" :src="ruleForm.picUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">提示：只能上传jpg/png文件，且不超过100kb，图片尺寸1:1</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 二级分类添加商品 -->
    <products :show="addProductDialog" ref="addref" @onCancel="cancellist" @onSubmit="handleSubmit"></products>
    <!-- 图片查看 -->
    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[imgUrl]" />
  </div>
</template>
<script>
import products from "./components/productsDialog.vue"
import ElImageViewer from "element-ui/packages/image/src/image-viewer" // 导入大图预览组件
function createRuleForm() {
  return {
    categoryName: "", // 类目名
    categoryId: "", // 类目id
    sort: "", // 排序
    picUrl: "", // 图片
    secondType: "", // 分类类型：1商品分类；2教育分类
    parentCategoryId: "",
    parentCategoryName: "",
    parentCategorySort: "",
  }
}
export default {
  name: "typeList",
  components: {
    products,
    ElImageViewer,
  },
  data() {
    return {
      dataLoding: false,
      categoryName: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      imgUrl: "", //默认网图
      showViewer: false, // 显示查看器、
      importFiles: [],
      // 弹窗
      addApi: true, //新增分类
      twoAdd: true, //二级分类新增
      twoEdit: true, //二级分类修改
      addCategoryId: null,
      categoryIdOne: null,
      categoryIdTwo: null,
      dialogTitle: "新增", // 弹窗标题
      dialogVisible: false, // 弹窗显隐
      addProductDialog: false, // 弹窗显隐
      ruleForm: createRuleForm(),
      rules: {
        parentCategoryName: [{ required: true, message: "请输入类目名", trigger: "change" }],
        parentCategorySort: [{ required: true, message: "请输入排序", trigger: "change" }],
        secondType: [{ required: true, message: "请输入分类类型", trigger: "change" }],
        categoryName: [{ required: true, message: "请输入类目名", trigger: "change" }],
        sort: [
          { required: true, message: "请输入排序", trigger: "change" },
          { pattern: /^[1-9]\d*$/, message: "请输入正整数", trigger: "change" },
        ],
        // 开发环境可注释
        picUrl: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    }
  },
  created() {
    this.getCategoryList()
    // this.getCategoryParentList();
  },
  methods: {
    getCategoryList() {
      this.dataLoding = true
      this.$axios
        .get(this.$api.categoryList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            auditStatus: 1,
            categoryName: this.categoryName || null,
          },
        })
        .then(res => {
          this.dataLoding = false
          const result = res.data.result
          this.tableData = result.list
          this.totalItemsCount = result.totalCount
        })
    },
    getCategoryParentList() {
      this.$axios
        .get(this.$api.categoryParentList)
        .then(res => {
          this.parentList = res.data.result
        })
        .catch(err => {
          console.error(err)
        })
    },
    async submitForm(formName) {
      try {
        // 校验表单
        await new Promise((resolve, reject) => {
          this.$refs[formName].validate(valid => {
            if (!valid) {
              this.$message.error("内容不完整，提交失败！")
              reject(new Error("表单校验失败")) // 拒绝Promise并传递错误
            } else {
              resolve() // 校验成功，解决Promise
            }
          })
        })
        // console.log(this.categoryIdOne);
        // let params = Object.assign({}, this.ruleForm);
        let params = {}
        let url = ""
        let msg = ""
        let method = ""
        if (this.addApi) {
          // 新增
          url = this.$api.categoryAddTwoCategory
          msg = "新增"
          method = "post"
          params.type = 1
          if (!this.twoAdd) {
            // 新增一级
            params.categoryName = this.ruleForm.parentCategoryName
            params.sort = this.ruleForm.parentCategorySort
            params.picUrl = this.ruleForm.picUrl
            params.secondType = this.ruleForm.secondType
          } else {
            // 新增二级
            params.categoryName = this.ruleForm.categoryName
            params.sort = this.ruleForm.sort
            params.parentId = this.ruleForm.parentCategoryId
          }
          // let categoryCode = this.parentList.find(
          //   (t) => t.categoryId == params.parentCategoryId
          // )?.categoryCode;
          // Object.assign(params, {
          //   categoryCode,
          // });
        } else {
          // 编辑
          url = this.$api.changeCategoryInfoPutTwo
          msg = "修改"
          method = "put"
          if (!this.twoEdit) {
            // 修改一级
            params.categoryId = this.ruleForm.parentCategoryId
            params.categoryName = this.ruleForm.parentCategoryName
            params.sort = this.ruleForm.parentCategorySort
            params.picUrl = this.ruleForm.picUrl
            params.secondType = this.ruleForm.secondType
          } else {
            // 修改二级
            params.categoryId = this.ruleForm.categoryId
            params.categoryName = this.ruleForm.categoryName
            params.sort = this.ruleForm.sort
            params.parentId = this.ruleForm.parentCategoryId
          }
        }
        this.submitLoding = true
        this.$axios[method](url, params).then(res => {
          this.submitLoding = false
          if (res.data.code === 100) {
            this.handleCloseDialog()
            this.getCategoryList()
            // this.getCategoryParentList()
            this.$message.success(`${msg}成功`)
          } else {
            this.$message.error(`${msg}失败`)
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    handleEdit(item) {
      this.categoryIdOne = item.categoryId
      let { categoryName, categoryId, picUrl, sort, parentCategoryId, parentCategoryName, parentCategorySort, secondType } = item
      // let categoryNameOne = "";
      // let categoryNameTwo = "";
      if (parentCategoryId == 0) {
        // categoryNameOne = categoryName;
        // this.categoryIdOne = item.categoryId;
        this.twoEdit = false
        this.dialogTitle = "修改一级分类"
        this.ruleForm = {
          parentCategoryId: categoryId,
          parentCategoryName: categoryName,
          parentCategorySort: sort,
          picUrl,
          secondType,
        }
      } else {
        // categoryNameOne = this.parentList.find(
        //   (t) => t.categoryId == parentCategoryId
        // )?.categoryName;
        // categoryNameTwo = categoryName;
        // this.categoryIdOne = parentCategoryId;
        // this.categoryIdTwo = categoryId;
        this.twoEdit = true
        this.dialogTitle = "修改二级分类"
        this.ruleForm = {
          categoryId,
          categoryName,
          sort,
          secondType,
          parentCategoryId,
          parentCategoryName,
          parentCategorySort,
        }
      }
      this.addApi = false
      this.dialogVisible = true
    },
    handleAdd() {
      this.ruleForm = createRuleForm()
      this.addApi = true
      this.twoAdd = false
      this.twoEdit = false
      this.dialogTitle = "新增一级分类"
      this.dialogVisible = true
    },
    handleAddTwo(item) {
      let { categoryName, categoryId, sort } = item
      this.ruleForm = {
        categoryId: "",
        categoryName: "",
        sort: "",
        parentCategoryId: categoryId,
        parentCategoryName: categoryName,
        parentCategorySort: sort,
      }
      this.addApi = true
      this.twoAdd = true
      this.twoEdit = true
      this.dialogTitle = "新增二级分类"
      this.dialogVisible = true
    },
    handleUpdateProduct(row) {
      this.addCategoryId = row.categoryId
      this.addProductDialog = true
    },
    closeAddProductDialog() {
      this.addProductDialog = false
    },
    handleCloseDialog() {
      this.$refs["ruleForm"].resetFields()
      this.categoryIdOne = null
      this.categoryIdTwo = null
      this.dialogVisible = false
      this.ruleForm = createRuleForm()
      this.dialogTitle = "新增"
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onScreenData()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getCategoryList()
    },
    // 筛选
    onScreenData() {
      this.currentPage = 1
      this.tableData = []
      this.getCategoryList()
    },
    // 删除
    handleDelete(item) {
      this.$confirm("确定删除该分类？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          return this.$axios.put(`${this.$api.categoryDelete}/${item.categoryId}`)
        })
        .then(res => {
          if (res.data.code == 100) {
            this.$message.success("删除成功")
            this.getCategoryList()
          } else {
            this.$message.error("删除失败")
          }
        })
    },
    // 图片上传
    handleSuccess(res) {
      if (res.code == 100) {
        this.ruleForm.picUrl = res.result.fileUrl
        this.$message({
          message: "上传成功！",
          type: "success",
        })
      } else {
        this.$message({ showClose: true, message: res.desc, type: "error" })
      }
    },
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
    // 点击按钮预览图片
    handleCommand(row) {
      this.imgUrl = row.picUrl
      this.showViewer = true
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    cancellist() {
      this.addProductDialog = false
    },
    handleSubmit(e) {
      this.loading = true
      let arr = e.map(item => item.productSupplierId)
      this.$axios
        .post(this.$api.updateProductCategory, {
          categoryId: this.addCategoryId,
          productIdList: arr,
        })
        .then(res => {
          if (res.data.code == 100) {
            this.$message.success("添加成功")
            this.addProductDialog = false
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;

  .input-box {
    margin: 0 20px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px #bfbebe dashed;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.demo_ruleForm {
  .el-row {
    width: 100% !important;
  }
}
</style>
